import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';

import fetchJsonp from 'fetch-jsonp';
import SmartFeedJobAlerts from './SmartFeedJobAlerts';
import { navigate } from 'gatsby';
// Progress Group Housing
import 'url-search-params-polyfill';

require('es6-promise').polyfill();

let prevCount = 0;
let currentCount = 0;
let keywordTime = null;

const slugify = function slugify(text) {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters

    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -
};

const SmartFeedFiltersComponent = (props) => {
  const id = 1;
  const [vacancy, setVacancy] = useState([]);
  const [vacancyTitle, setVacancyTitle] = useState([]);
  const [isIinitialLoad, setIsIinitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  // Filter States
  const [locationFilter, setLocationFilter] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [industryFilter, setIndustryFilter] = useState([]);
  const [postingTypeFilter, setPostingTypeFilter] = useState([]);
  const [jobTypeFilter, setJobTypeFilter] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState([]);
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([]);
  const [autocompleteListHide, setAutocompleteListHide] = useState(true);
  const [filterState, setFilterState] = useState({});
  const [vacsCount, setVacsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreText, setLoadMoreText] = useState(false);

  const [rodMessageContents, setRodMessageContents] = useState(false);

  // Button filters
  const [careersNavState, setCareersNavState] = useState('careers');

  function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  }

  function setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  function getAdvertDataNotification(jobs) {
    return Promise.all(
      jobs.map(async (id) => {
        return fetchJsonp(
          'https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=' + id
        ).then(function(response) {
          return response.json();
        });
      })
    );
  }

  function checkIfWeShouldShowROD() {
    const activeClass = '-active';
    const notification = document.querySelector(
      '#recruitmentOpenDayNotification'
    );
    const rodButton = document.getElementById('js-close-rod');

    const notificationInner = document.getElementById(
      'recruitmentOpenDayNotification__content'
    );

    let notificationInnerHTML = '';

    if (!getCookie('show-rod-message')) {
      console.log('showing the rod message!');

      fetchJsonp(
        'https://sf.vacancy-filler.co.uk/CareerPage/GetResultList?id=' +
          props.apiKey +
          '&' +
          'sortby=jobtitle' +
          '&' +
          'sortdir=desc' +
          '&' +
          'postingtype=' +
          'careers' +
          '&' +
          'category=' +
          'Recruitment%20Event'
      )
        .then(function(response) {
          return response.json();
        })
        .then(async function(jsonList) {
          if (jsonList) {
            await getAdvertDataNotification(jsonList).then(function(data) {
              if (data && data[0]) {
                notificationInnerHTML =
                  '<div class="row">' +
                  '<div class="col-12">' +
                  '<small class="notification__pre_title">' +
                  'Vacancy Notification:' +
                  '</small>' +
                  '<h2 class="notification__title">' +
                  data[0].JobTitle +
                  '</h2>' +
                  '</div>' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="notification__image col-12 col-4-m">' +
                  '<img src="https://cdn1.accropress.com/ProgressGroupHousingCareers/cards/' +
                  getImageFromRegionCards(data[0].Region) +
                  '.png' +
                  '"' +
                  ' alt="' +
                  data[0].Region +
                  '"' +
                  '/>' +
                  '</div>' +
                  '<div class="col-12 col-8-m">' +
                  '<p class="notification__message">' +
                  data[0].Summary +
                  '</p>' +
                  '</div>' +
                  '</div>' +
                  '<div class="notification__footer">' +
                  '<button ' +
                  'id="js-open-rod" ' +
                  'class="notification__button -primary -jobApply"' +
                  '>' +
                  'FIND OUT MORE' +
                  '</a>' +
                  '</div>' +
                  '</div>';

                setRodMessageContents(notificationInnerHTML);

                notification.classList.add(activeClass);

                rodButton.addEventListener('click', () => {
                  setCookie('show-rod-message', true, { expires: 7 });
                  notification.classList.remove(activeClass);
                });

                document
                  .getElementById('js-open-rod')
                  .addEventListener('click', () => {
                    setCookie('show-rod-message', true, { expires: 7 });
                    notification.classList.remove(activeClass);

                    let navLink =
                      '/vacancies/vacancy/' +
                      encodeURIComponent(data[0].Id) +
                      '?company=' +
                      data[0].Region;

                    navigate(navLink);
                  });
              } else {
                console.log('No rod to show :(');
              }
            });
          }
        });
    }
  }

  const addActiveClassnameToNav = function addActiveClassnameToNav(stateName) {
    if (stateName === careersNavState) {
      return 'VF_Nav_Item_Active';
    }

    return '';
  };

  let groupOrIdParam = 'id';

  if (props.group) {
    groupOrIdParam = 'group';
  }

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        'https://sf.vacancy-filler.co.uk/CareerPage/' +
          name +
          '?' +
          groupOrIdParam +
          '=' +
          props.apiKey
      )
        .then(function(response) {
          return response.json();
        })
        .then(function(jsonList) {
          let temp = ['All ' + label];

          setState(temp.concat(removeDuplicates(jsonList)));

          props.setVacancyLoading(false);
        })
        .catch(function(ex) {
          setLoading(false);
          console.log(ex, 'error');
        });
    } catch (error) {
      setLoading(false);
      console.log(error.message, 'error');
    }
  };

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    console.log(state, name);
    console.log('careersNavState:');
    console.log(careersNavState);
    console.log(careersNavState);

    let postingTypeSelectionValue = careersNavState;

    if (careersNavState !== postingTypeSelection && postingTypeSelection) {
      postingTypeSelectionValue = postingTypeSelection;
    }

    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText('Loading.....');

    if (name) {
      prevCount = 0;
      currentCount = 0;
    }

    let temp = state;

    if (name) {
      if (e) {
        if (e.target && e.target.value.includes(name)) {
          e.target.value = '';
        }
      }

      if (name === 'autocomplete') {
        temp.latitude = e.lat;
        temp.longitude = e.long;
        temp.distance = e.distance;
        temp.unit = 'Miles';
      } else if (name === 'location') {
        if (e.target.value.includes('All ')) {
          delete temp.location;
        } else {
          temp.location = e.target.value;
        }
      } else if (name === 'keywords') {
        temp.keywords = e.target.value;
      } else if (name === 'region') {
        if (e.target.value.includes('All ')) {
          delete temp.region;
        } else {
          temp.region = e.target.value;
        }
      } else if (name === 'category') {
        if (e.target.value.includes('All ')) {
          delete temp.category;
        } else {
          temp.category = e.target.value;
        }
      } else if (name === 'industry') {
        if (e.target.value.includes('All ')) {
          delete temp.industrysector;
        } else {
          temp.industrysector = e.target.value;
        }
      } else if (name === 'postingType') {
        if (e.target.value.includes('All ')) {
          delete temp.postingtype;
        } else {
          temp.postingtype = e.target.value;
        }
      } else if (name === 'jobtype') {
        if (e.target.value.includes('All ')) {
          delete temp.jobtype;
        } else {
          temp.jobtype = e.target.value;
        }
      } else if (name === 'jobtime') {
        if (e.target.value.includes('All ')) {
          delete temp.jobtime;
        } else {
          temp.jobtime = e.target.value;
        }
      } else if (name === 'country') {
        if (e.target.value.includes('All ')) {
          delete temp.country;
        } else {
          temp.country = e.target.value;
        }
      } else if (name === 'companySearch') {
        if (e.target.value.includes('All ')) {
          delete temp.CompanySearch;
        } else {
          temp.CompanySearch = e.target.value;
        }
      } else if (name === 'reset') {
        delete temp.CompanySearch;
        delete temp.country;
        delete temp.jobtime;
        delete temp.jobtype;
        delete temp.postingtype;
        delete temp.industrysector;
        delete temp.category;
        delete temp.region;
        delete temp.latitude;
        delete temp.longitude;
        delete temp.distance;
        delete temp.unit;

        if (document && document.getElementById('Keywords')) {
          document.getElementById('Keywords').value = '';
        }
      }

      setFilterState(temp);
    }

    function getAdvertData(jobs) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            'https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=' + id
          ).then(function(response) {
            return response.json();
          });
        })
      );
    }

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
      setLoading(true);
    }

    try {
      fetchJsonp(
        'https://sf.vacancy-filler.co.uk/CareerPage/GetResultList?id=' +
          props.apiKey +
          '&' +
          'sortby=jobtitle' +
          '&' +
          'sortdir=asc' +
          '&' +
          'postingtype=' +
          postingTypeSelectionValue +
          '&' +
          buildQuery(temp)
      )
        .then(function(response) {
          return response.json();
        })
        .then(async function(jsonList) {
          prevCount = currentCount;
          currentCount = currentCount + 10;

          await getAdvertData(jsonList).then(function(data) {
            if (loadMore) {
              setVacancy(vacancy.concat(data));
            } else {
              setVacancy(data);
            }

            if (jsonList.length === 0) {
              setVacancyTitle(
                'We are currently recruiting for ' +
                  jsonList.length +
                  ' positions matching your search.'
              );
            } else {
              setVacancyTitle(
                'We are currently recruiting for ' +
                  jsonList.length +
                  ' positions.'
              );
            }
            setLoading(false);

            setInitialLoading(false);

            setLoadMoreText('CLICK HERE FOR MORE JOB OPPORTUNITIES');
            setVacsCount(jsonList.length);
          });
        });
    } catch (error) {
      setLoading(false);
      console.log(error.message, 'error');
    }
  };

  const chooseGeoLocation = function chooseGeoLocation(lat, long, distance) {
    setAutocompleteListHide(true);
    fetchVacancies(
      filterState,
      'autocomplete',
      { lat: lat, long: long, distance: distance },
      false
    );
  };

  const renderFilter = function renderFilter(data, label, stateName) {
    if (label === 'Keyword Search') {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={'select-' + label}>
            {label}:{' '}
          </label>
          <input
            defaultValue={filterState[stateName]}
            onChange={(e) => {
              e.persist();
              keywordSearchChange(filterState, stateName, e, false);
            }}
            className="VF-form-control onchange-search form-control"
            id="Keywords"
            name="Keywords"
            placeholder="Enter keywords"
            type="text"
            aria-labelledby="keywordSearchLabel"
            aria-describedby="Enter keywords"
          />
        </div>
      );
    } else if (label === 'Search Location') {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={'select-' + label}>
            {label}:{' '}
          </label>
          <div className="vf-filter-loc-complete-wrap">
            <input
              value={filterState[stateName]}
              onChange={(e) =>
                autocompleteSearchChange(filterState, label, e, false)
              }
              className="VF-form-control onchange-search form-control"
              id="Keywords"
              name="Keywords"
              placeholder="Enter postcode, town or city"
              type="text"
              aria-labelledby="keywordSearchLabel"
              aria-describedby="Enter keywords"
            />
            {!autocompleteListHide &&
              data &&
              Array.isArray(data) &&
              data.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {data.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() =>
                        chooseGeoLocation(loc.latitude, loc.longitude, 10)
                      }
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="vf-filter">
          <label aria-label={label} htmlFor={'select-' + stateName}>
            {label == 'Locations' ? 'Services' : label}:{' '}
          </label>
          <select
            className="form-control"
            value={filterState[stateName] || data[0]}
            onChange={(e) => fetchVacancies(filterState, stateName, e, false)}
            name={'select-' + stateName}
            id={'select-' + stateName}
          >
            {data.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  const clickCurrentVacancies = function clickCurrentVacancies() {
    setCareersNavState('careers');
    fetchVacancies(filterState, undefined, undefined, false, 'careers');
  };

  const clickInternalVacancies = function clickInternalVacancies() {
    setCareersNavState('internal');
    fetchVacancies(filterState, undefined, undefined, false, 'internal');
  };

  const clickRegisterVacancies = function clickRegisterVacancies() {
    setCareersNavState('register');
    fetchVacancies(filterState, undefined, undefined, false, 'register');
  };

  const clickAlertsVacancies = function clickAlertsVacancies() {
    setCareersNavState('alerts');
  };

  const loadMore = function loadMore() {
    fetchVacancies(filterState, undefined, undefined, true);
  };

  const resetVacs = function resetVacs() {
    fetchVacancies({}, 'reset', undefined, false);
  };

  const searchVacs = function searchVacs() {
    fetchVacancies({}, 'search', undefined, false);
  };

  const autocompleteSearchChange = function autocompleteSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    setAutocompleteListHide(false);

    let val = e.currentTarget.value;

    if (e.currentTarget.value.length > 3) {
      // Do api call to get key
      fetchJsonp(
        'https://sf.vacancy-filler.co.uk/CareerPage/GetLocationAPIKey?id=' +
          props.apiKey
      )
        .then(function(response) {
          return response.json();
        })
        .then(function(geoKey) {
          // Do api call to get locs
          fetch(
            'https://geocoding.vacancy-filler.co.uk/api/V2/exact/?apikey=' +
              geoKey.key +
              '&search=' +
              encodeURIComponent(val)
          )
            .then((res) => res.json())
            .then((result) => {
              setAutoCompleteFilter(result.locationResults);
            });
        });
    }
  };

  const keywordSearchChange = function keywordSearchChange(
    state,
    name,
    e,
    loadMore
  ) {
    clearTimeout(keywordTime);

    keywordTime = setTimeout(() => {
      fetchVacancies(state, name, e, loadMore);
    }, 500);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const [savedJobs, setSavedJobs] = useStatePersist(
    '@savedJobs_' + props.apiKey,
    []
  );

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    if (props.trackEvent) {
      props.trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'unSaveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    if (props.trackEvent) {
      props.trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'saveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
    }
    return NewSalary;
  };
  const getImageFromRegionCards = function getImageFromRegionCards(region) {
    let jobCardRegionNoSpaces = region.replace(/[, ]+/g, '-');

    return jobCardRegionNoSpaces;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const buildQuery = function buildQuery(data) {
    if (typeof data === 'string') return data;
    let query = [];

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        );
      }
    }

    return query.join('&');
  };

  useEffect(() => {
    if (isIinitialLoad) {
      setIsIinitialLoad(false);
      var urlParams = new URLSearchParams(window.location.search);
      var entries = urlParams.entries();
      let temp = {};
      for (let pair of entries) {
        temp[pair[0]] = pair[1];
      }

      setFilterState(temp);

      const timeOut = setTimeout(() => {
        fetchVacancies(temp, undefined, undefined, false);
        if (props.group) {
          fetchFilters(
            'GetLocationListByGroup',
            setLocationFilter,
            'Locations'
          ); // Locations
          fetchFilters('GetRegionListByGroup', setRegionFilter, 'Locations'); // Regions
          fetchFilters(
            'GetCategoryListByGroup',
            setCategoryFilter,
            'Categories'
          ); // Categories
          fetchFilters(
            'GetIndustryListByGroup',
            setIndustryFilter,
            'Industries'
          ); // Industries
          fetchFilters(
            'GetPostingTypesListByGroup',
            setPostingTypeFilter,
            'Posting Types'
          ); // Posting Types
          fetchFilters('GetJobTypeListByGroup', setJobTypeFilter, 'Job Types'); // Job Types
        } else {
          fetchFilters('GetLocations', setLocationFilter, 'Locations'); // Locations
          fetchFilters('GetRegions', setRegionFilter, 'Locations'); // Regions
          fetchFilters('GetCategories', setCategoryFilter, 'Categories'); // Categories
          fetchFilters('GetIndustries', setIndustryFilter, 'Industries'); // Industries
          fetchFilters(
            'GetPostingTypes',
            setPostingTypeFilter,
            'Posting Types'
          ); // Posting Types
          fetchFilters('GetJobTypes', setJobTypeFilter, 'Job Types'); // Job Types
        }

        checkIfWeShouldShowROD();
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [careersNavState]);

  return (
    <div id="VF_vacancies">
      {!props.vacancyLoading && (
        <div className="row VF_vacancies_main_wrap">
          <div className="col-12">
            <div className="VF_Nav_Wrap">
              <div className="row VF_Nav_Items">
                <button
                  id="VF_Nav_Item_Current"
                  onClick={() => clickCurrentVacancies()}
                  className={
                    'col-12 col-3-m VF_Nav_Item VF_Nav_Item_Current ' +
                    addActiveClassnameToNav('careers')
                  }
                >
                  Current vacancies
                </button>
                <button
                  id="VF_Nav_Item_Internal"
                  onClick={() => clickInternalVacancies()}
                  className={
                    'col-12 col-3-m VF_Nav_Item VF_Nav_Item_Current ' +
                    addActiveClassnameToNav('internal')
                  }
                >
                  Internal vacancies
                </button>
                <button
                  id="VF_Nav_Item_Register"
                  onClick={() => clickRegisterVacancies()}
                  className={
                    'col-12 col-3-m VF_Nav_Item VF_Nav_Item_Register ' +
                    addActiveClassnameToNav('register')
                  }
                >
                  Register your interest
                </button>
                <button
                  id="VF_Nav_Item_Alerts"
                  onClick={() => clickAlertsVacancies()}
                  className={
                    'col-12 col-3-m VF_Nav_Item VF_Nav_Item_Alerts ' +
                    addActiveClassnameToNav('alerts')
                  }
                >
                  Sign up to job alerts
                </button>
              </div>
            </div>

            {careersNavState !== 'alerts' ? (
              <div className="VF_vacancies_wrap">
                <div className="VF_filter_form">
                  <div className="row VF_filter_form_row">
                    <div className="col-12 col-12-m VF_filter_form_item">
                      {renderFilter(
                        keywordFilter,
                        'Keyword Search',
                        'keywords'
                      )}
                    </div>
                  </div>
                  <div className="row VF_filter_form_row">
                    <div className="col-12 col-4-m VF_filter_form_item">
                      {renderFilter(
                        categoryFilter,
                        'Job Categories',
                        'category'
                      )}
                    </div>
                    <div className="col-12 col-4-m VF_filter_form_item">
                      {renderFilter(regionFilter, 'Locations', 'region')}
                    </div>
                    <div className="col-12 col-4-m VF_filter_form_item">
                      {renderFilter(jobTypeFilter, 'Job Types', 'jobtype')}
                    </div>
                  </div>
                  <div className="row VF_filter_form_row VF_filter_form_row_buttons">
                    <div className="col-12 col-6-m col-spacer-block"></div>
                    <div className="col-12 col-3-m">
                      {/* <button
                    className="btn btn-primary"
                    onClick={() => searchVacs()}
                  >
                    Search
                  </button> */}
                    </div>
                    <div className="col-12 col-3-m">
                      <button
                        className="btn btn-secondary"
                        onClick={() => resetVacs()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div class="company-filter-container">
                  <div class="company-filter-row">
                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          var e = { target: { value: 'Progress Housing' } };

                          fetchVacancies(filterState, 'region', e, false);
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="progress-housing-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/progress-housing-group.png"
                            alt="Progress Housing"
                          />
                        </div>
                        <div className="margintop">
                          <h3>Progress Housing Group</h3>
                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>

                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          var e = { target: { value: 'key' } };

                          fetchVacancies(filterState, 'region', e, false);
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="key-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/key.png"
                            alt="Key"
                          />
                        </div>
                        <div className="margintop">
                          <h3>Key</h3>
                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>

                    {/* <div class="company-filter-card">
                    <div
                      onClick={() => {
                        var e = { target: { value: 'Concert Living' } };

                        fetchVacancies(filterState, 'region', e, false);
                      }}
                      class="company-filter-card-inner"
                    >
                      <div>
                        <img
                          className="concert-living-img"
                          src="https://cdn1.accropress.com/ProgressGroupHousingCareers/logos/ConcertLiving.png"
                          alt="Concert Living"
                        />
                      </div>
                      <h3>Concert Living</h3>
                      <p>
                        <a>View Jobs</a>
                      </p>
                    </div>
                  </div> */}

                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          var e = { target: { value: 'Progress Lifeline' } };

                          fetchVacancies(filterState, 'region', e, false);
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="progress-lifeline-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/progress-housing-group-2.png"
                            alt="Progress Lifeline"
                          />
                        </div>
                        <div className="margintop">
                          <h3>Progress Lifeline</h3>

                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="company-filter-row">
                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          var e = { target: { value: 'Progress Living' } };

                          fetchVacancies(filterState, 'region', e, false);
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="progress-living-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/progress-living.png"
                            alt="Progress Living"
                          />
                        </div>
                        <div className="margintop">
                          <h3>Progress Living</h3>
                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>

                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          var e = { target: { value: 'Reside with Progress' } };

                          fetchVacancies(filterState, 'region', e, false);
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="reside-with-progress-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/rwp.png"
                            alt="Reside with Progress"
                          />
                        </div>
                        <div className="margintop">
                          <h3>RWP</h3>
                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>

                    <div class="company-filter-card">
                      <div
                        onClick={() => {
                          fetchVacancies(
                            filterState,
                            'reset',
                            undefined,
                            false
                          );
                        }}
                        class="company-filter-card-inner"
                      >
                        <div>
                          <img
                            className="all-locations-img"
                            src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Images/Cards/progress-team-1.png"
                            alt="All Locations"
                          />
                        </div>

                        <div class="margintop">
                          <h3>All Locations</h3>
                          <span>View Jobs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 VF_vacancies_list">
                  <div className="row">
                    {loading ? (
                      'Loading vacancies...'
                    ) : (
                      <>
                        {vacancy &&
                        vacancy !== [] &&
                        vacancy !== '[]' &&
                        !initialLoading ? (
                          <>
                            {vacancy && vacancy.length > 0 ? (
                              vacancy.map((job, i) => (
                                <div
                                  key={i}
                                  className="col-12 col-4-m vacancy-card-wrap"
                                >
                                  <div className="vacancy vacancy-card vacancy-card-v2">
                                    <div className="row">
                                      <div className="col-12 vacancy-main-details">
                                        <div className="vacancy-main-details-inner">
                                          <h3 className="job-title">
                                            {job.JobTitle}
                                          </h3>

                                          <div className="job-info-container row">
                                            <div className="vacancy-details-wrap col-12">
                                              <strong>Salary: </strong>
                                              <span>
                                                {calculateSalary(
                                                  job.Currency,
                                                  job.SalaryFrom,
                                                  job.SalaryTo,
                                                  job.SalaryType,
                                                  job.SalaryPeriod
                                                )}
                                              </span>
                                            </div>
                                            <div className="vacancy-details-wrap col-12">
                                              <strong>Closing Date: </strong>
                                              <span>
                                                {FormatDateTime(job.ExpiryDate)}
                                              </span>
                                            </div>
                                            <div className="vacancy-details-wrap col-12">
                                              <strong>Location: </strong>
                                              <span>{job.Location}</span>
                                            </div>
                                            {/* <div className="vacancy-details-wrap col-12">
                                              <strong>Region: </strong>
                                              <span>{job.Region}</span>
                                            </div> */}
                                            <div className="vacancy-details-wrap col-12">
                                              <strong>Job Type: </strong>
                                              <span>{job.JobType}</span>
                                            </div>
                                            <div className="vacancy-details-wrap col-12">
                                              <strong>Reference No: </strong>
                                              <span>{job.AdvertReference}</span>
                                            </div>
                                          </div>

                                          <div className="job-summary">
                                            {getSummary(
                                              job.Summary,
                                              job.Description
                                            )}
                                          </div>
                                          <div className="extra content">
                                            <div className="ui two buttons">
                                              <a
                                                className="btn btn-primary"
                                                href={
                                                  '/vacancies/vacancy/' +
                                                  new Buffer(job.Id).toString(
                                                    'base64'
                                                  ) +
                                                  '/' +
                                                  slugify(job.JobTitle) +
                                                  '?company=' +
                                                  encodeURIComponent(
                                                    getImageFromRegionCards(
                                                      job.Region
                                                    )
                                                  )
                                                }
                                              >
                                                More info
                                              </a>
                                              <button
                                                className="btn btn-link"
                                                onClick={() => {
                                                  if (
                                                    savedJobs.filter(
                                                      (e) =>
                                                        e == job.Id.toString()
                                                    ).length > 0
                                                  ) {
                                                    unSaveJob(job.Id);
                                                  } else {
                                                    saveJob(job.Id);
                                                  }
                                                }}
                                              >
                                                {savedJobs.filter(
                                                  (e) => e == job.Id.toString()
                                                ).length == 0 ? (
                                                  <span className="fas fa-heart">
                                                    Save
                                                  </span>
                                                ) : (
                                                  <span className="far fa-heart">
                                                    Un save
                                                  </span>
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="col-12 col-xs-12 vacancy-secondary-details">
                                        <div className="vacancy-brand-image-block">
                                          <img
                                            src={
                                              "https://cdn1.accropress.com/ProgressGroupHousingCareers/cards/" +
                                              getImageFromRegionCards(
                                                job.Region
                                              ) +
                                              ".png"
                                            }
                                            alt={job.Region}
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src =
                                                'https://cdn1.accropress.com/ProgressGroupHousingCareers/logos/progress-default.png';
                                            }}
                                          />
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <>
                                {careersNavState !== 'register' ? (
                                  <div className="no-results">
                                    Sorry, we don't have any vacancies matching
                                    your search.
                                  </div>
                                ) : (
                                  <div className="no-results">
                                    There are no opportunities to register for
                                    at this time. Please see our current
                                    vacancies page for more information. Thank
                                    you for your interest in working for us.
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div className="no-vacancies">
                            Loading vacancies...
                          </div>
                        )}

                        <div className="load-more-wrap col-12">
                          {currentCount < vacsCount && (
                            <a
                              style={{
                                textAlign: 'center',
                                display: 'block',
                                width: '100%',
                              }}
                              className="btn btn-primary"
                              onClick={() => loadMore()}
                            >
                              {loadMoreText}
                            </a>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <SmartFeedJobAlerts
                apiKey={props.apiKey}
                gdprKey={props.gdprKey}
                group={props.group}
                location={true}
                region={true}
                category={true}
                industry={true}
                jobType={true}
                jobTime={true}
                CompanyGroupName={props.CompanyGroupName}
                VacancyPageBaseURL={props.VacancyPageBaseURL}
                ReturnToSearchURL={props.ReturnToSearchURL}
              />
            )}
          </div>
        </div>
      )}

      <div id="recruitmentOpenDayNotification" className="notification">
        <div className="notification__content">
          <div className="notification__header">
            <div id="js-close-rod" className="notification__button -secondary">
              <small>Close</small>
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: rodMessageContents,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SmartFeedFiltersComponent;
